<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('delivery.lang_orderStatus')"
                :subheading="$t('delivery.lang_orderStatus')"
                :icon="icon"
                show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <OrderStatusCreateComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import OrderStatusCreateComponent from "@/components/delivery/settings/OrderStatusCreateComponent";


export default {
  name: "OrderStatusCreate",
  components: {
    OrderStatusCreateComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-settings icon-gradient bg-tempting-azure',
  })
}
</script>

